// src/components/Benefits.tsx
import React from 'react';

const Why: React.FC = () => {
  return (
    <div id="Why" className="w3-row-padding w3-padding-64 w3-container bg-gray-200">
      <div className="container mx-auto px-4 py-8">
        <div className="rounded-lg p-6">
          <h1 className="text-3xl text-center mb-8 font-lexend">¿Por qué Implementar?</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6" style={{ fontFamily: 'Inter, sans-serif' }}>
  <div>
    <h2 className="text-2xl font-semibold text-gray-700 mb-4 font-lexend">Gestión de Activos</h2>
    <ul className="list-disc list-inside text-gray-600 mb-6">
      <li><strong>Visibilización y Documentación</strong>: Asegura una completa visibilidad y documentación del total de los activos, facilitando su gestión y control.</li>
      <li><strong>Programación y Seguimiento de Tareas</strong>: Permite la programación eficiente de tareas de mantenimiento y su seguimiento en tiempo real.</li>
    </ul>
    <h2 className="text-2xl font-semibold text-gray-700 mb-4 font-lexend">Optimización de Costos</h2>
    <ul className="list-disc list-inside text-gray-600 mb-6">
      <li><strong>Reducción de Costos de Mantenimiento</strong>: Reduce los costos de mantenimiento entre un 20-40%, optimizando recursos y procesos.</li>
      <li><strong>Disminución de Costos de Reparación</strong>: Logra una disminución de los costos de reparación en un 30-60% mediante mantenimiento preventivo y predictivo.</li>
    </ul>
  </div>
  <div>
    <h2 className="text-2xl font-semibold text-gray-700 mb-4 font-lexend">Mejora en la Disponibilidad de Equipos</h2>
    <ul className="list-disc list-inside text-gray-600 mb-6">
      <li><strong>Aumento de la Vida Útil de los Equipos</strong>: Aumenta la disponibilidad y vida útil de los equipos en un 20-40%, gracias a una gestión de mantenimiento eficaz.</li>
    </ul>
    <h2 className="text-2xl font-semibold text-gray-700 mb-4 font-lexend">Beneficios Adicionales</h2>
    <ul className="list-disc list-inside text-gray-600 mb-6">
      <li><strong>Mejora Continua</strong>: La implementación de estrategias de mejora continua para optimizar todos los procesos de mantenimiento.</li>
      <li><strong>Adaptabilidad y Escalabilidad</strong>: Soluciones que se adaptan a las necesidades cambiantes de la industria, asegurando escalabilidad y flexibilidad.</li>
    </ul>
  </div>
</div>
        </div>
      </div>
    </div>
  );
};

export default Why;
