// src/components/Header.tsx
import React from "react";

const Header: React.FC = () => {
  return (
    <header
      className="w3-container w3-center"
      style={{
        padding: "228px 16px",
        background: "linear-gradient(to right, #136dae, #0a9ae7, #7ccdff)",
      }}
    >
      <h1
        className="text-4xl sm:text-5xl md:text-5xl lg:text-6xl xl:text-7xl font-lexend text-center"
        style={{ color: "white", lineHeight: "1.5"}}
      >
        Gestión de activos y mantenimiento
        <br />
        simple
      </h1>
      <p
        className="w3-xlarge text-center"
        style={{ fontFamily: "Inter, sans-serif" }}
      >
        &nbsp;
      </p>
      <div className="w3-xlarge text-center">
        <button
          className="text-2xl sm:text-2xl md:text-2xl lg:text-2xl xl:text-2xl font-inter w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 mx-auto"
          style={{
            fontWeight: "600",
            padding: "10px",
            borderRadius: "50px",
            backgroundColor: "black",
            color: "white",
            border: "2px solid black",
          }}
        >
          <a
            className="text-2xl sm:text-2xl md:text-2xl lg:text-2xl xl:text-2xl font-inter w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 mx-auto"
            style={{
              fontWeight: "600",
              padding: "10px",
              borderRadius: "50px",
              backgroundColor: "black",
              color: "white",
              border: "2px solid black",
            }}
            href="#Contact"
          >
            Comenzar
          </a>
        </button>
      </div>
    </header>
  );
};

export default Header;
