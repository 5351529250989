// src/components/Header.tsx
import React from "react";

const Footer: React.FC = () => {
  return (
    <footer
      className="w3-container w3-center"
      style={{
        padding: "228px 16px",
        // background: "linear-gradient(to right, #136dae, #0a9ae7, #7ccdff)",
        backgroundColor: "black",
      }}
    >
      <h1
        className="text-4xl sm:text-5xl md:text-5xl lg:text-6xl xl:text-7xl font-lexend text-center"
        style={{ color: "white" }}
      >
        Gestión de activos y mantenimiento simple
      </h1>
      <p
        className="w3-xlarge text-center text-4xl sm:text-5xl md:text-5xl lg:text-6xl xl:text-7xl mt-20"
        style={{ fontFamily: "Inter, sans-serif", color: "white" }}
      >
        ¡Comienza ahora!
      </p>
      
    </footer>
  );
};

export default Footer;
