// src/components/Benefits.tsx
import React from 'react';

const Benefits: React.FC = () => {
  return (
    <div id="Benefits" className="w3-row-padding w3-padding-64 w3-container">
      <div className="container mx-auto px-4 py-8">
        <div className="rounded-lg p-6">
          <h1 className="text-3xl text-center mb-8 font-lexend">Beneficios</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6" style={{ fontFamily: 'Inter, sans-serif' }}>
            <div>
              <h2 className="text-2xl font-semibold text-gray-700 mb-4 font-lexend">Eficiencia Operacional</h2>
              <ul className="list-disc list-inside text-gray-600 mb-6">
                <li><strong>Automatización de Tareas</strong>: Wartun permite automatizar tareas de mantenimiento, reduciendo el tiempo y esfuerzo necesarios.</li>
                <li><strong>Gestión Centralizada</strong>: Centraliza todas las tareas de mantenimiento, facilitando el seguimiento y la coordinación.</li>
              </ul>
              <h2 className="text-2xl font-semibold text-gray-700 mb-4 font-lexend">Reducción de Costos</h2>
              <ul className="list-disc list-inside text-gray-600 mb-6">
                <li><strong>Optimización de Recursos</strong>: Mejor planificación y uso de recursos, evitando costos innecesarios.</li>
                <li><strong>Mantenimiento Preventivo</strong>: Implementación de estrategias de mantenimiento preventivo, reduciendo fallos inesperados.</li>
              </ul>
              <h2 className="text-2xl font-semibold text-gray-700 mb-4 font-lexend">Mejora en la Calidad del Servicio</h2>
              <ul className="list-disc list-inside text-gray-600 mb-6">
                <li><strong>Tiempo de Respuesta</strong>: Mejora los tiempos de respuesta ante incidencias, incrementando la satisfacción del cliente.</li>
                <li><strong>Transparencia y Comunicación</strong>: Facilita la comunicación y el reporte de problemas, aumentando la transparencia.</li>
              </ul>
            </div>
            <div>
              <h2 className="text-2xl font-semibold text-gray-700 mb-4 font-lexend">Análisis y Reportes</h2>
              <ul className="list-disc list-inside text-gray-600 mb-6">
                <li><strong>Datos en Tiempo Real</strong>: Proporciona datos en tiempo real para una toma de decisiones informada.</li>
                <li><strong>Reportes Detallados</strong>: Genera reportes que ayudan a analizar el rendimiento y eficiencia de las operaciones.</li>
              </ul>
              <h2 className="text-2xl font-semibold text-gray-700 mb-4 font-lexend">Cumplimiento Normativo</h2>
              <ul className="list-disc list-inside text-gray-600 mb-6">
                <li><strong>Registros Históricos</strong>: Mantiene registros detallados de todas las actividades de mantenimiento.</li>
                <li><strong>Documentación y Procedimientos</strong>: Asegura que todos los procedimientos se documenten adecuadamente.</li>
              </ul>
              <h2 className="text-2xl font-semibold text-gray-700 mb-4 font-lexend">Flexibilidad y Escalabilidad</h2>
              <ul className="list-disc list-inside text-gray-600 mb-6">
                <li><strong>Adaptabilidad</strong>: La herramienta se adapta a las necesidades de diferentes industrias.</li>
                <li><strong>Crecimiento</strong>: Es escalable y se ajusta a nuevas necesidades y volúmenes de trabajo.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
