import React, { useState } from "react";

const Contact: React.FC = () => {
  // Estado inicial para los valores del formulario
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    moreDetail: "",
  });

  // Maneja el cambio en los inputs del formulario
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Maneja el envío del formulario
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    //create a call to API https://api.wartun.com/api/Common/contact with formData as body
    submitContactForm(formData);

    // Aquí puedes agregar la lógica para enviar los datos del formulario, como enviarlos a una API
    console.log(formData);
    // Restablecer el formulario
    setFormData({
      name: "",
      email: "",
      phoneNumber: "",
      moreDetail: "",
    });
  };

  async function submitContactForm(formData: {
    name: string;
    email: string;
    phoneNumber: string;
    moreDetail: string;
  }) {
    try {
      // const response = await fetch('https://localhost:7028/api/Common/contact', {
      const response = await fetch(
        "https://api.wartun.com/api/Common/contact",
        {
          method: "POST",
          body: JSON.stringify(formData) as BodyInit, // Convert formData to JSON
          headers: {
            "Content-Type": "application/json", // Set the Content-Type header
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Form submitted successfully:", data);
      return data;
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  }

  return (
    <div id="Contact" className="w3-row-padding w3-padding-64 w3-container">
      <div className="container mx-auto px-4 py-8">
        <div className="rounded-lg p-6">
          <h1 className="text-3xl text-center mb-8 font-lexend">Contacto</h1>
          <div className="container mx-auto px-4 py-8 flex flex-col md:flex-row">
            {/* Contenedor del Formulario */}
            <div className="flex-1 rounded-lg p-6 mb-4 md:mb-0 m-30">
              <h1 className="text-3xl text-center mb-8 font-lexend">
                Completa el formulario
              </h1>
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Nombre
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Correo electrónico
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Teléfono
                  </label>
                  <input
                    type="phoneNumber"
                    name="phoneNumber"
                    id="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="message"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Mensaje
                  </label>
                  <textarea
                    name="moreDetail"
                    id="moreDetail"
                    rows={4}
                    value={formData.moreDetail}
                    onChange={handleChange}
                    className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm"
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700"
                >
                  Enviar
                </button>
              </form>
            </div>
            {/* Contenedor de Información de Contacto */}
            <div className="flex-1 rounded-lg p-6 text-center">
              <h2 className="text-3xl text-center mb-8 font-lexend">
                Otros medios
              </h2>
              <p className="text-lg mb-4">
                <strong>Teléfono</strong>
                <br />
                +54 9 11 5455-4524
              </p>
              {/* <p className="text-lg mb-4"><strong>Email:</strong> contacto@nuestrositio.com</p> */}
              <p className="text-lg mb-4">
                <strong>WhatsApp</strong>
                <br />
                +54 9 11 2791-0239
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
