// src/App.tsx
import React from 'react';
import Navbar from './components/Navbar';
import Header from './components/Header';
import Benefits from './components/Benefits';
import Why from './components/Why';
import Contact from './components/Contact';
import Footer from './components/Footer';
// Importa otros componentes según sea necesario

const App: React.FC = () => {
  return (
    <div>
      <Navbar />
      <Header />
      <Benefits />
      <Why />
      <Contact />
      <Footer />
      {/* Agrega otros componentes aquí */}
    </div>
  );
};

export default App;


// import React from 'react';
// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.tsx</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
