// src/components/Navbar.tsx
import React, { useState } from "react";
import wartunImage from "../images/WARTUNG_01.jpg";
import hamburgerImage from "../images/hamburger.png";

const Navbar: React.FC = () => {
  // Estado para manejar si el elemento está mostrado o no
  const [show, setShow] = useState(false);

  // Función para alternar la visibilidad
  const toggleShow = () => {
    setShow(!show);
  };

  // Estado para controlar la visibilidad del menú
  const [isOpen, setIsOpen] = useState(false);

  // Función para alternar la visibilidad del menú
  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <div
      className="w3-top"
      style={{ position: "fixed", width: "100%", backgroundColor: "white" }}
    >
      <div
        className="show-on-medium-large"
        style={{ fontFamily: "Inter, sans-serif" }}
      >
        <nav className="navbar-container">
          <a href="#Top" className="w3-bar-item w3-white">
            <img
              src={wartunImage}
              alt="Wartun"
              style={{ maxHeight: "35px", width: "auto" }}
            />
          </a>
          <a
            href="#Top"
            className="w3-bar-item w3-button w3-hide-small w3-padding-large w3-hover-blue"
          >
            Inicio
          </a>
          <a
            href="#Benefits"
            className="w3-bar-item w3-button w3-hide-small w3-padding-large w3-hover-blue"
          >
            Beneficios
          </a>
          <a
            href="#Why"
            className="w3-bar-item w3-button w3-hide-small w3-padding-large w3-hover-blue"
          >
            ¿Por qué Implementar?
          </a>
          {/* <a
            href="#Link3"
            className="w3-bar-item w3-button w3-hide-small w3-padding-large w3-hover-blue"
          >
            Confian en nosotros
          </a> */}
          <a
            href="#Contact"
            className="w3-bar-item w3-button w3-hide-small w3-padding-large w3-hover-blue"
          >
            Contacto
          </a>
          <button
            style={{
              paddingRight: "20px",
              paddingLeft: "20px",
              paddingTop: "5px",
              paddingBottom: "5px",
              borderRadius: "50px",
              backgroundColor: "#136dae",
              color: "white",
              marginTop: "10px",
              marginBottom: "10px",
              fontWeight: "600",
            }}
            title="Iniciar Sesión"
            onClick={() => window.open("https://app.wartun.com", "_blank")}
          >
            Iniciar Sesión
          </button>
        </nav>
      </div>

      <nav className="show-on-small">
        <div className="small-navbar-container">
          <a href="#Top" className="w3-bar-item w3-white">
            <img
              src={wartunImage}
              alt="Wartun"
              style={{ maxHeight: "35px", width: "auto" }}
            />
          </a>
          {/* Botón del menú hamburguesa */}
          <div className="menu-s" onClick={toggleMenu}>
            {/* Icono del menú (puede ser una imagen o un ícono de alguna librería) */}
            <img src={hamburgerImage} alt={"Menu"} width="40px" />
          </div>
        </div>

        <div className="flex justify-end">
          <div
            id="navDemo"
            className={`menu-content ${isOpen ? "show" : "hide"}`}
          >
            <a href="#Top" onClick={toggleMenu}>
              Inicio
            </a>
            <a href="#Benefits" onClick={toggleMenu}>
              Beneficios
            </a>
            <a href="#Why" onClick={toggleMenu}>
              ¿Por qué Implementar?
            </a>
            {/* <a href="#Link3" onClick={toggleMenu}>
              Confian en nosotros
            </a> */}
            <a href="#Contact" onClick={toggleMenu}>
              Contacto
            </a>
            <div
              style={{ backgroundColor: "transparent", textAlign: "center" }}
            >
              <button
                style={{
                  paddingRight: "20px",
                  paddingLeft: "20px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  borderRadius: "50px",
                  backgroundColor: "#136dae",
                  color: "white",
                  marginTop: "10px",
                  marginBottom: "10px",
                  fontWeight: "600",
                }}
                title="Iniciar Sesión"
                onClick={() => window.open("https://app.wartun.com", "_blank")}
              >
                Iniciar Sesión
              </button>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
